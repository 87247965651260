import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faCarSide } from '@fortawesome/pro-regular-svg-icons/faCarSide';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faPencilAlt } from '@fortawesome/pro-regular-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faEraser } from '@fortawesome/pro-solid-svg-icons/faEraser';
import { SavedSearch } from '@propertypal/shared/src/types/search';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import { openTooltip } from '@propertypal/web-ui/src/notifications/Tooltip';
import { Text } from '@propertypal/web-ui/src/typography';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { useTheme } from 'styled-components';
import useTouchDevice from '../../hooks/useTouchDevice';
import { getMapInstance, getZoom } from './GoogleMap';
import {
  ControlsContainer,
  Control,
  TypeContainer,
  TypeButton,
  SavedSearchButton,
  DrawControls,
  ZoomControls,
} from './MapControls.style';

interface Props {
  zoomControl?: boolean;
  mapTypeControl?: boolean;
  drawingActive?: boolean;
  eraseActive?: boolean;
  travelTimeActive?: boolean;
  onDrawClick?: () => void;
  onEraseClick?: () => void;
  onTrashClick?: () => void;
  onTravelTimeClick?: () => void;
  savable?: boolean;
  disableSaveSearch?: boolean;
  onSaveSearchClick?: () => void;
  savedSearch?: SavedSearch['search'];
  containerStyle?: CSSProperties;
}

const MapControls: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [type, setType] = useState('roadmap');
  const touchDevice = useTouchDevice();

  const handleZoom = (delta: number) => {
    const map = getMapInstance();

    if (map) {
      map.setZoom(getZoom() + delta);
    }
  };

  const handleTypeChange = (nextType: string) => {
    const map = getMapInstance();

    if (map) {
      setType(nextType);
      map.setMapTypeId(nextType);
    }
  };

  return (
    <>
      {props.mapTypeControl && (
        <TypeContainer role="radiogroup" aria-label="Map terrain options">
          <TypeButton
            aria-checked={type === 'roadmap'}
            active={type === 'roadmap'}
            onClick={() => handleTypeChange('roadmap')}
            data-testid="mapTypeButton"
            role="radio"
            type="button"
          >
            Map
          </TypeButton>

          <TypeButton
            aria-checked={type === 'hybrid'}
            active={type === 'hybrid'}
            onClick={() => handleTypeChange('hybrid')}
            data-testid="satelliteTypeButton"
            role="radio"
            type="button"
          >
            Satellite
          </TypeButton>
        </TypeContainer>
      )}

      <ControlsContainer style={props.containerStyle}>
        {props.zoomControl && !touchDevice && (
          <ZoomControls>
            <Control aria-label="Zoom in" onClick={() => handleZoom(1)} data-testid="zoomInButton" type="button">
              <FontAwesomeIcon icon={faPlus} color={theme.white} style={{ fontSize: 20 }} />
            </Control>

            <Control aria-label="Zoom out" onClick={() => handleZoom(-1)} data-testid="zoomOutButton" type="button">
              <FontAwesomeIcon icon={faMinus} color={theme.white} style={{ fontSize: 20 }} />
            </Control>
          </ZoomControls>
        )}

        {(props.onDrawClick || props.onEraseClick || props.onTrashClick) && (
          <DrawControls data-testid="draw-controls">
            <Text align="center" fontSize={14} fontWeight="600" mb={3}>
              Draw
            </Text>

            {props.onDrawClick && (
              <Control
                aria-label="Draw a search"
                active={props.drawingActive}
                onClick={props.onDrawClick}
                data-testid="drawButton"
                type="button"
              >
                <FontAwesomeIcon icon={faPencilAlt} color={theme.white} style={{ fontSize: 20 }} />
              </Control>
            )}

            {props.onEraseClick && (
              <Control
                aria-label="Erase search"
                active={props.eraseActive}
                onClick={props.onEraseClick}
                data-testid="eraseButton"
                type="button"
              >
                <FontAwesomeIcon icon={faEraser} color={theme.white} style={{ fontSize: 20 }} />
              </Control>
            )}

            {props.onTrashClick && (
              <Control
                aria-label="Delete drawn search"
                onClick={props.onTrashClick}
                data-testid="trashButton"
                type="button"
              >
                <FontAwesomeIcon icon={faTrashAlt} color={theme.white} style={{ fontSize: 20 }} />
              </Control>
            )}
          </DrawControls>
        )}

        {props.onTravelTimeClick && (
          <DrawControls>
            <Text align="center" fontSize={14} fontWeight="600" mb={3}>
              Travel Time
            </Text>

            <Control
              aria-label="Search by travel time"
              active={props.travelTimeActive}
              onClick={props.onTravelTimeClick}
              data-testid="travelTimeButton"
              type="button"
            >
              <FontAwesomeIcon icon={faCarSide} color={theme.white} style={{ fontSize: 20 }} />
            </Control>
          </DrawControls>
        )}
      </ControlsContainer>

      {props.savable && props.onSaveSearchClick && (
        <SavedSearchButton
          onClick={() => {
            if (props.onSaveSearchClick && !props.disableSaveSearch) {
              props.onSaveSearchClick();
            }
          }}
          data-testid="saveSearchButton"
          disableSaveSearch={props.disableSaveSearch}
          onMouseEnter={(e) => {
            if (props.disableSaveSearch) {
              openTooltip(
                'You must click the "Search" button in the search form to update your results before you can Save this search.',
                e,
              );
            }
          }}
        >
          <FontAwesomeIcon icon={faBell} />
          {props.savedSearch ? 'Edit saved' : 'Save this'} search
        </SavedSearchButton>
      )}
    </>
  );
};

export default MapControls;
