import { largeTabletMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import { GREY_600, GREY_900 } from '@propertypal/shared/src/styles/theme';
import styled from 'styled-components';
import { filterBreakpoint } from '../search/SearchBar/SearchBar.style';

const CONTROL_RIGHT_SPACE = 10;
const CONTROL_PADDING = 4;

export const ControlsContainer = styled.div`
  position: absolute;
  top: 65px;
  right: ${CONTROL_RIGHT_SPACE - CONTROL_PADDING}px;
  z-index: 1;

  @media (${mobileXXLargeMax}) {
    top: 55px;
  }
`;

export const ZoomControls = styled.div`
  padding: 5px ${CONTROL_PADDING}px;
  background: ${({ theme }) => theme.white};
  border-radius: 8px;

  & button:last-child {
    margin-bottom: 0;
  }
`;

export const DrawControls = styled.div`
  background: ${({ theme }) => theme.white};
  padding: 5px ${CONTROL_PADDING}px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
  width: 52px;

  & button:last-child {
    margin-bottom: 0;
  }

  @media (${mobileXXLargeMax}) {
    margin-top: 5px;
  }
`;

export const Control = styled.button<{ active?: boolean; draw?: boolean }>`
  background-color: ${({ active, theme }) => (active ? GREY_600 : theme.primary)};
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: 300ms all;
  border-radius: 4px;

  &:hover {
    background-color: ${GREY_900};
  }
`;

export const TypeContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
`;

export const TypeButton = styled.button<{ active?: boolean }>`
  width: 120px;
  height: 46px;
  text-align: center;
  color: ${(props) => (props.active ? props.theme.background : props.theme.primary)};
  background-color: ${(props) => (props.active ? props.theme.primary : props.theme.background)};
`;

export const SavedSearchButton = styled.button<{ disableSaveSearch?: boolean }>`
  width: 200px;
  background-color: ${(props) => props.theme.primary};
  height: 44px;
  text-align: center;
  font-weight: 600;
  border-radius: 40px;
  color: ${(props) => props.theme.white};
  font-size: 16px;
  position: fixed;
  bottom: 30px;
  left: 10px;
  z-index: 4;

  ${(props) =>
    props.disableSaveSearch &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}

  svg {
    margin-right: 10px;
  }

  @media (${largeTabletMax}) {
    bottom: 85px;
  }

  @media (${filterBreakpoint}) {
    display: none;
  }
`;
