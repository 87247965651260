import styled from 'styled-components';

const MapContainer = styled.div`
  position: relative;

  .gm-style-iw {
    padding: 5px !important;

    .info-window-image {
    }

    .info-window-text {
      max-width: 120px;
      max-height: 60px;
      min-height: 20px;
      display: flex;
      align-items: center;
    }

    .gm-style-iw-d {
      overflow: auto !important;
    }
  }

  .advanced-marker {
    position: relative;
    background-color: white;
    border-radius: 4px;
    padding: 5px;

    img {
      max-width: 120px;
      max-height: 60px;
    }

    :after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;

      border-top: 10px solid white;
    }
  }

  .pp-map-marker {
    position: relative;

    p {
      position: absolute;
      color: white;
      text-align: center;
      top: 5px;
      left: 0;
      right: 0;
      font-weight: bold;
      font-size: 14px;
    }

    .pp-marker-plus {
      top: 1px;
      font-size: 18px;
    }
  }
`;

export default MapContainer;
